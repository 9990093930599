var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"comments"},[_c('div',{staticClass:"sms-label"},[_c('p',[_vm._v("СМС заявителю")]),(_vm.history.length)?_c('p',[_vm._v("последнее отправленное:")]):_vm._e(),(_vm.history.length)?_c('p',[_vm._v(_vm._s(_vm.history[_vm.history.length - 1].date))]):_c('p',[_vm._v("(пока нет отправленных)")])]),_c('div',{staticClass:"sms-content"},[(_vm.history.length)?_c('div',{staticClass:"sms-last"},[_vm._v(" "+_vm._s(_vm.history[_vm.history.length - 1].text)+" ")]):_vm._e(),_c('div',{staticClass:"sms-buttons"},[_c('button',{attrs:{"type":"button"},on:{"click":function($event){return _vm.$modal.show('modal-send-sms')}}},[_vm._v(" Отправить СМС ")]),_c('button',{attrs:{"type":"button"},on:{"click":function($event){return _vm.$modal.show('sms-history-modal')}}},[_vm._v(" История ")])])]),_c('modal',{attrs:{"name":"modal-send-sms","styles":"border-radius: 12px;","width":"645px","height":"auto","adaptive":true,"clickToClose":false}},[_c('div',{staticClass:"modal-send-sms__wrapper"},[_c('div',{staticClass:"modal-send-sms__title"},[_vm._v(" Отправить СМС заявителю ")]),_c('Select',{attrs:{"name":"smsType","label":"Выбрать заготовку","value":_vm.smsType,"options":_vm.smsTypeOptions},on:{"change":function (evt) {_vm.smsType = evt.target.value}}}),(
                    _vm.smsType !== null &&
                    Number(_vm.smsType) === _vm.SMS_TYPE_OPTION_IDS.APPLICANT_IS_EXPECTED
                )?_c('Input',{attrs:{"name":"visitingDateTime","label":"Дата визита","mask":'99-99-2099 99:99',"valid":_vm.isValidDateTime,"touched":true,"invalidityInfo":_vm.visitingDateTime ? 'некорректное значение' : 'введите дату и время',"value":_vm.visitingDateTime},on:{"change":function (evt) {_vm.visitingDateTime = evt.target.value}}}):_vm._e(),_c('TextArea',{attrs:{"name":"sendingText","label":"Текст сообщения","disabled":_vm.smsType === null ||
                    Number(_vm.smsType) !== _vm.SMS_TYPE_OPTION_IDS.OTHER,"value":_vm.sendingText,"minHeight":'210px'},on:{"change":function (evt) {_vm.sendingText = evt.target.value}}}),_vm._v(" "),(_vm.isNotAllowed)?_c('div',{staticClass:"modal-send-sms__warning"},[_c('span',[_vm._v("Внимание! Последнее СМС было отправлено менее 12 часов назад. Отправка нового СМС пока недоступна")])]):_vm._e()],1),_c('div',{staticClass:"modal-send-sms__buttons"},[_c('Button',{on:{"click":function () {
                    _vm.$modal.hide('modal-send-sms');
                    _vm.clear();
                }}},[_vm._v(" Отмена ")]),_c('Button',{attrs:{"disabled":_vm.isNotAllowed ||
                    (Number(_vm.smsType) === _vm.SMS_TYPE_OPTION_IDS.APPLICANT_IS_EXPECTED
                    ? !_vm.sendingText || !_vm.isValidDateTime
                    : !_vm.sendingText)},on:{"click":function($event){return _vm.onClickSend({smsType: _vm.smsType, text: _vm.sendingText})}}},[_vm._v(" Отправить ")])],1)]),_c('modal',{attrs:{"name":"sms-history-modal","styles":"border-radius: 12px;","width":"645px","height":"645px","adaptive":true}},[_c('div',{staticClass:"sms-history-wrapper"},[_c('div',{staticClass:"sms-history-title"},[_vm._v("История СМС")]),(_vm.loading)?_c('div',{staticClass:"loader"},[_c('Loader')],1):_c('div',[(_vm.history.length)?_c('div',{staticClass:"sms-history-items"},_vm._l((_vm.history),function(item,index){return _c('div',{key:index,staticClass:"sms-history-item"},[_c('div',{staticClass:"sms-history-item__label"},[_c('p',[_vm._v(_vm._s(item.date))])]),_c('div',{staticClass:"sms-history-item__text"},[_vm._v(_vm._s(item.text))])])}),0):_c('div',{staticClass:"sms-history-empty"},[_vm._v(" Пока не отправлено ни одного СМС ")])]),_c('div',{staticClass:"sms-history-buttons"},[_c('Button',{on:{"click":function($event){return _vm.$modal.hide('sms-history-modal')}}},[_vm._v(" Закрыть ")])],1)])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }