export enum SMS_TYPE_OPTION_IDS {
  APPLICANT_IS_EXPECTED = 0,
  APPLICANT_IS_NOT_AVAILABLE = 1,
  // OTHER = 2, // пока не требуется
};

export const SMS_TYPE_OPTIONS = {
  [SMS_TYPE_OPTION_IDS.APPLICANT_IS_EXPECTED]: 'Вас ожидают в офисе',
  [SMS_TYPE_OPTION_IDS.APPLICANT_IS_NOT_AVAILABLE]: 'Не смогли связаться',
  // [SMS_TYPE_OPTION_IDS.OTHER]: 'Другое', // пока не требуется
};
