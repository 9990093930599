
































import Vue from "vue";
import { mapState, mapActions, mapGetters } from "vuex";

import PreAppProposalForm from "@/components/PreAppProposalForm.vue";
import PreAppProducts from "@/components/PreAppProducts.vue";
import PreAppFormDocument from "@/components/PreAppFormDocument.vue";
import { Tab, Tabs } from "@/components/Tabs";

export default Vue.extend({
  name: "PreAppFormRequest",

  components: {
    PreAppProposalForm,
    PreAppProducts,
    PreAppFormDocument,
    Tabs,
    Tab
  },

  computed: {
    ...mapState("preApplicationState", [
      "activeTab",
      "statusId",
      "isEdit",
      "isFormValid",
    ]),
    ...mapGetters("preApplicationState", [
      "getClientDocuments",
      "isIdentification",
      "isIdentificationClosed"
    ])
    // ...mapGetters("preApplicationState", ["getUpload"])
  },

  methods: {
    ...mapActions("preApplicationState", [
      "setActiveTab"
    ])

    // isProductInOrder(docInfos: IDocInfo[], type: InitialFileTypes): boolean {
    //   return this.getInfoDoc(docInfos, type) != undefined;
    // }

    // getInfoDoc(docInfos: IDocInfo[], type: InitialFileTypes) : any {
    //   return docInfos.find(d => d.typeId == type);
    // }
  }
})
