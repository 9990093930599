






















































































































import Vue from "vue";
import { mapActions, mapState, mapGetters } from "vuex";

import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";
import PageLayout from "@/components/layouts/PageLayout.vue";
import PreAppFormRequest from "@/components/PreAppFormRequest.vue";
import Button from "@/components/UI/Button.vue";
import ArrowLeft from "@/components/UI/icon/ArrowLeft.vue";
import Pencil from "@/components/UI/icon/Pencil.vue";
import Comment from "@/components/UI/icon/Comment.vue";
import IconBase from "@/components/UI/IconBase.vue";
import ModalWithField from "@/components/ModalWithField.vue";

import { scrollToInvalidFormItem } from "@/helpers/scrollToInvalidFormItem";
import { IDENTIFICATION_NOTIFICATIONS } from "@/store/modules/preapplication/preapplication.constants";
import { PRODUCT_BINDS } from "@/store/modules/products/products.constants";

export default Vue.extend({
  name: "PreApplication",

  components: {
    PreAppFormRequest,
    Button,
    ArrowLeft,
    Pencil,
    IconBase,
    Comment,
    PageLayout,
    Header,
    Footer,
    ModalWithField
  },

  data() {
    return {
      identificationNotifications: Object.values(IDENTIFICATION_NOTIFICATIONS),
      showTooltip: false,
    }
  },

  computed: {
    ...mapState("preApplicationState", [
      "isEdit",
      "activeTab",
      "managerComment",
      "statusId",
      "orderId",
      "isFormValid",
    ]),
    ...mapGetters("preApplicationState", [
      "isIdentification",
      "isIdentificationClosed",
      "correctManagerComment",
      "hasTariffLicenseUnlimited",
      "disabledClickReadyForUpd",
      "updStatusTitle",
      "disabledChangePreappWithLicense",
    ]),
    ...mapGetters("authorizationState", ["isManagerPartner"]),
    ...mapGetters("productsState", ["getProductsSelected"]),
  },

  methods: {
    ...mapActions("preApplicationState", [
      "clearFromControlsHandler",
      "fetchPreApplication",
      "createApplication",
      "changeStatus",
      "isGoodToChangeStatus",
      "isGoodToCreateApp",
      "editPreApp",
      "checkValidity",
      "setActiveTab",
      "clearManagerComment",
      "clearPreAppType",
      "approveIdentification",
      "declainIdentification",
      "cleanAllUploads",
      "readyForUpd",
      "defineAddressFields",
      "defineLicenseFields",
      "setNotRequiredLicenseFields"
    ]),
     ...mapActions("productsState", ["resetProducts"]),
     ...mapActions("comments", ["getLastComment", "clearCommentsState"]),

    createAppHandleClick() {
      if (this.isEdit) {
        this.$modal.show("dialog", {
          title: "ВНИМАНИЕ",
          text: "Предзаявка находится в режиме редактирования.<br />" + 
            "Перед созданием заявки сохраните или отмените сделанные изменения<br />",
          buttons: [{title: "Ок"}]
        });
      } else {
        this.checkValidity();
        if (this.isFormValid) {
          this.createApplication();
        } else {
          this.setActiveTab(1);
          scrollToInvalidFormItem();
        }
      }
    },

    createLicenseHandleClick() {
      if (this.isEdit) {
        this.$modal.show("dialog", {
          title: "ВНИМАНИЕ",
          text: "Предзаявка находится в режиме редактирования.<br />" + 
            "Перед формированием лицензии сохраните или отмените сделанные изменения<br />",
          buttons: [{title: "Ок"}]
        });
      } else {
        this.checkValidity();
        if (this.isFormValid) {
          this.readyForUpd();
        } else {
          this.setActiveTab(1);
          scrollToInvalidFormItem();
        }
      }
    },
  },

  watch: {
    managerComment() {
      if (this.managerComment !== null) {
        this.getLastComment(this.managerComment);
      }
    },

    getProductsSelected() {
      const hasTariffInsurance = this.getProductsSelected
        .some(({bind}: {bind: number}) => PRODUCT_BINDS.Insurance.includes(bind));
      this.defineAddressFields(hasTariffInsurance);
    },

    orderId() {
      if (
        !this.isIdentification &&
        !this.disabledClickReadyForUpd &&
        this.hasTariffLicenseUnlimited
      ) {
        this.defineLicenseFields();
        this.setNotRequiredLicenseFields();
      }
    },
  },

  mounted() {
    const { orderId } = this.$route.params;
    if (orderId) {
      this.fetchPreApplication(orderId);
    }
  },

  destroyed() {
    this.clearFromControlsHandler();
    this.resetProducts();
    this.clearManagerComment();
    this.clearCommentsState();
    this.clearPreAppType();
    this.cleanAllUploads();
    this.changeStatus(false);
  }
})
