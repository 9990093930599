<template>
    <div class="comments">
        <div class="sms-label">
            <p>СМС заявителю</p>
            <p v-if="history.length">последнее отправленное:</p>
            <p v-if="history.length">{{history[history.length - 1].date}}</p>
            <p v-else>(пока нет отправленных)</p>
        </div>
        <div class="sms-content">
            <div v-if="history.length" class="sms-last">
                {{ history[history.length - 1].text }}
            </div>
            <div class="sms-buttons">
                <button type="button" @click="$modal.show('modal-send-sms')">
                    Отправить СМС
                </button>
                <button type="button" @click="$modal.show('sms-history-modal')">
                    История
                </button>
            </div>
        </div>
        <modal 
            name="modal-send-sms"
            styles="border-radius: 12px;"
            width="645px"
            height="auto"
            :adaptive="true"
            :clickToClose="false"
        >
            <div class="modal-send-sms__wrapper">
                <div class="modal-send-sms__title">
                    Отправить СМС заявителю
                </div>
                <Select
                    name="smsType"
                    label="Выбрать заготовку"
                    :value="smsType"
                    :options="smsTypeOptions"
                    @change="(evt) => {smsType = evt.target.value}"
                />
                <Input
                    v-if="
                        smsType !== null &&
                        Number(smsType) === SMS_TYPE_OPTION_IDS.APPLICANT_IS_EXPECTED
                    "
                    name="visitingDateTime"
                    label="Дата визита"
                    :mask="'99-99-2099 99:99'"
                    :valid="isValidDateTime"
                    :touched="true"
                    :invalidityInfo="visitingDateTime ? 'некорректное значение' : 'введите дату и время'"
                    :value="visitingDateTime"
                    @change="(evt) => {visitingDateTime = evt.target.value}"
                />
                <TextArea
                    name="sendingText"
                    label="Текст сообщения"
                    :disabled="
                        smsType === null ||
                        Number(smsType) !== SMS_TYPE_OPTION_IDS.OTHER
                    "
                    :value="sendingText"
                    :minHeight="'210px'"
                    @change="(evt) => {sendingText = evt.target.value}"
                />
                <div v-if="isNotAllowed" class="modal-send-sms__warning">
                    <span>Внимание! Последнее СМС было отправлено менее 12 часов назад. Отправка нового СМС пока недоступна</span>
                </div>
            </div>

            <div class="modal-send-sms__buttons">
                <Button
                    @click="() => {
                        $modal.hide('modal-send-sms');
                        clear();
                    }"
                >
                    Отмена
                </Button>
                <Button
                    :disabled="
                        isNotAllowed ||
                        (Number(smsType) === SMS_TYPE_OPTION_IDS.APPLICANT_IS_EXPECTED
                        ? !sendingText || !isValidDateTime
                        : !sendingText)
                    "
                    @click="onClickSend({smsType, text: sendingText})"
                >
                    Отправить
                </Button>
            </div>
        </modal>
        <modal
            name="sms-history-modal"
            styles="border-radius: 12px;"
            width="645px"
            height="645px"
            :adaptive="true"
        >
            <div class="sms-history-wrapper">
                <div class="sms-history-title">История СМС</div>
                <div v-if="loading" class="loader">
                    <Loader />
                </div>
                <div v-else>
                    <div v-if="history.length" class="sms-history-items">
                        <div
                            v-for="(item, index) in history"
                            :key="index"
                            class="sms-history-item"
                        >
                            <div class="sms-history-item__label">
                                <p>{{ item.date }}</p>
                            </div>
                            <div class="sms-history-item__text">{{ item.text }}</div>
                        </div>
                    </div>
                    <div v-else class="sms-history-empty">
                        Пока не отправлено ни одного СМС
                    </div>
                </div>
                <div class="sms-history-buttons">
                    <Button @click="$modal.hide('sms-history-modal')">
                        Закрыть
                    </Button>
                </div>
            </div>
        </modal>
    </div>
</template>

<script>
import TextArea from '@/components/UI/form/TextArea.vue';
import Button from '@/components/UI/Button.vue';
import Loader from '@/components/UI/Loader.vue';
import Select from "@/components/UI/form/Select.vue";
import Input from "@/components/UI/form/Input.vue";
import { SMS_TYPE_OPTION_IDS } from '@/store/modules/sms/sms.config';

export default {
    components: {
        TextArea,
        Button,
        Loader,
        Input,
        Select,
    },
    props: {
        history: {
            type: Array,
            required: true,
            default: () => [],
        },
        smsTypeOptions: {
            type: Array,
            required: false,
            default: () => [],
        },
        filialAddress: {
            type: String,
            required: true,
        },
        filialPhone: {
            type: String,
            required: true,
        },
        filialName: {
            type: String,
            required: true,
        },
        isNotAllowed: {
            type: Boolean,
            required: true,
        },
    },
    data() {
        return {
            loading: false,
            sendingText: null,
            smsType: null,
            visitingDateTime: '',
            SMS_TYPE_OPTION_IDS,
        };
    },
    computed: {
        getSmsText() {
            if (this.smsType !== null && Number(this.smsType) === SMS_TYPE_OPTION_IDS.APPLICANT_IS_EXPECTED) {
                return `Вас ждут на получение электронной подписи ${this.formattedDate} по адресу: ${this.filialAddress}. Не забудьте паспорт, номер СНИЛС, печать и токен (при наличии). ${this.filialName} +7${this.filialPhone}`;
            }
            if (this.smsType !== null && Number(this.smsType) === SMS_TYPE_OPTION_IDS.APPLICANT_IS_NOT_AVAILABLE) {
                return `Не смогли до Вас дозвониться по вопросу получения электронной подписи, просим связаться с нами по телефону: +7${this.filialPhone}. УЦ "Основание"`;
            }
            if (this.smsType !== null && Number(this.smsType) === SMS_TYPE_OPTION_IDS.OTHER) {
                return '';
            }
            return null;
        },
        formattedDate() {
            if (this.visitingDateTime) {
                const date = [
                    this.visitingDateTime.slice(0, 2),
                    this.visitingDateTime.slice(2, 4),
                    '20' + this.visitingDateTime.slice(4, 6),
                ].join('-');
                const time = [
                    this.visitingDateTime.slice(6, 8),
                    this.visitingDateTime.slice(8),
                ].join(':');
                return `${date} в ${time}`;
            }
            return '';
        },
        isValidDateTime() {
            if (this.visitingDateTime.length < 10) return false;
            const valids = [0, 2, 4, 6, 8]
                .map((d) => this.visitingDateTime.slice(d, d+2))
                .map((val, i) => {
                    if (i === 0) return Number(val) <= 31 && Number(val) !== 0; // день
                    if (i === 1) return Number(val) <= 12 && Number(val) !== 0; // месяц
                    if (i === 2) return Number(val) >= (new Date()).getFullYear() - 2000; // год
                    if (i === 3) return Number(val) <= 23; // час
                    if (i === 4) return Number(val) <= 59; // минуты
                });
            return !valids.includes(false);
        },
    },
    methods: {
        clear() {
            this.sendingText = null;
            this.smsType = null;
            this.visitingDateTime = '';
        },

        async onClickSend({smsType, text}) {
            const success = await this.$emit('sendSms', {smsType, text});
            if (success) {
                this.$modal.hide('modal-send-sms');
                this.clear();
            }
        },
    },
    watch: {
        getSmsText() {
            if (this.getSmsText !== null) {
                this.sendingText = this.getSmsText;
            }
        },
        smsType() {
            if (this.smsType !== SMS_TYPE_OPTION_IDS.APPLICANT_IS_EXPECTED) {
                this.visitingDateTime = '';
            }
        },
    },
    async mounted() {
        await this.$emit('getSmsList');
    },
    beforeDestroy() {
        this.$emit('clearSmsList');
    },
};
</script>

<style lang="scss">
.comments {
    position: relative;
    margin: 0 auto;
    display: flex;
    flex-direction: row;
}

.sms-label {
    width: calc(50% - 5px);
    font-size: 14px;
    color: #808080;
    margin-top: 10px;
    margin-right: 10px;
    p {
        margin: 0;
        padding-bottom: 2px;
    }
    p:nth-child(1) {
        padding-bottom: 5px;
    }
}

.sms-content {
    margin-top: 10px;
    width: calc(50% - 5px);
    font-size: 14px;
    color: #333;
}

.sms-buttons {
    display: grid;
    grid-template-columns: 200px 100px;

    button {
        border: none;
        background: none;
        margin-bottom: 5px;
        margin-right: 2em;
        padding: 0;
        text-align: left;
        cursor: pointer;
        &:hover {
            color: #6f61e9;
        }
    }
}

.sms-last {
    min-height: 83px;
    border-radius: 4px;
    font-size: 14px;
    line-height: 22px;
    color: #333;
    background: #f6f6f8;
    border: 1px solid transparent;
    margin-bottom: 5px;
    padding: 10px 12px;
    word-wrap: break-word;
}

.sms-history-title {
    font-size: 20px;
    font-weight: 700;
    color: #333;
    padding-bottom: 15px;
}
.sms-history-title {
    padding-bottom: 25px;
}

.sms-history-wrapper {
    display: grid;
    grid-template-rows: 49px 433px 73px;
    padding: 45px 5px;
    height: 100%;
    text-align: center;
}

.sms-history-items {
    height: 100%;
    overflow-y: scroll;
    padding: 0px 40px 25px 40px;
    &::-webkit-scrollbar {
        width: 6px;
    }
    &::-webkit-scrollbar-thumb {
        background: #6f61e9;
        border-radius: 3px;
    }
}

.sms-history-empty {
    height: 437px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.sms-history-buttons {
    padding-top: 25px;
}

.sms-history-item {
    display: flex;
    flex-direction: row;
    margin-top: 10px;
    &__label {
        width: calc(50% - 5px);
        margin-top: 10px;
        margin-right: 10px;
        text-align: left;
        font-size: 14px;
        color: #808080;
        p {
            margin: 1px 0;
        }
    }
    &__text {
        width: calc(50% - 5px);
        background: #fff;
        border: 1px solid #e0dfee;
        border-radius: 4px;
        text-align: left;
        font-size: 14px;
        line-height: 22px;
        color: #333;
        padding: 9px 12px;
        word-wrap: break-word;
    }
}

.loader {
    height: 437px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.modal-send-sms {
    &__wrapper {
        width: 100%;
        padding: 45px;
        text-align: center;
    }

    &__title {
        font-size: 20px;
        font-weight: 700;
        color: #333;
        padding-bottom: 25px;
    }

    &__buttons {
        display: flex;
        justify-content: space-between;
        padding: 0 45px 45px 45px;
    }

    &__warning {
        text-align: center;
        padding: 0;
        color: rgb(173,173,29);
    }
}
</style>
