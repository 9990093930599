









































import Vue from "vue";
import { mapGetters, mapActions } from "vuex";

import ItemDocument from "@/components/ItemDocument.vue";

export default Vue.extend({
  name: "PreAppFormDocument",

  components: {ItemDocument},

  props: {
    title: {
      type: String,
      required: true,
      default: ""
    },
    description: {
      type: String,
      required: false,
      default: ""
    },
    documents: {
      type: Array,
      required: true,
      default: () => []
    },
    isClientDocuments: {
      type: Boolean,
      required: false,
      default: false
    },
    typeUpload: {
      type: Boolean,
      required: false,
      default: false
    },
    typeDownload: {
      type: Boolean,
      required: false,
      default: false
    },
  },

  computed: {
    ...mapGetters("preApplicationState", ["isIdentificationClosed"]),

    isFilled() {
      return (item: any): boolean => {
        if (item.name.startsWith("another")){
          return !!item.isTouched;
        }
        return !!item.files && !!item.files.length;
      }
    }
  },

  methods: {
    ...mapActions("preApplicationState", [
      "fetchScanAndOpen",
      "inputFileHandler",
      "fetchAnotherUploads",
      "fetchAnotherDocument",
      "fotoCaptureHandler"
    ]),

    handleCLickItemDocument(item: any) {
      if (item.name.startsWith("another")) {
        this.fetchAnotherDocument(item.name);
      } else {
        this.fetchScanAndOpen(item);
      }
    }
  },

  mounted() {
    if (this.isClientDocuments) {
      this.fetchAnotherUploads();
    }
  }
})
